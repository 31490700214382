import './App.css';
import { Amplify } from 'aws-amplify';
import { fetchUserAttributes, fetchAuthSession } from 'aws-amplify/auth';

import { withAuthenticator, Button } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import {config} from './awsconfig'
import { EC2 } from 'aws-sdk';
import { useEffect, useState } from 'react';
import { ELBv2 } from 'aws-sdk';

Amplify.configure(config);

function App({ signOut, user }) {
  const [instanceStates, setInstanceStates] = useState({});
  const [servers, setServers] = useState([]);
  const [userName, setUserName] = useState("");


  const getEC2Client = async () => {
    const credentials = await fetchAuthSession();
    return new EC2({
      region: process.env.REACT_APP_CLOUD_REGION,
      credentials: credentials["credentials"],
    });
  };

  const getELBv2Client = async () => {
    const credentials = await fetchAuthSession();
    return new ELBv2({
      region: process.env.REACT_APP_CLOUD_REGION,
      credentials: credentials['credentials'],
    });
  };

  const fetchListenerRules = async () => {
    const elbClient = await getELBv2Client();
    const params = {
      ListenerArn: process.env.REACT_APP_ALB_LISTENER_ARN,
    };
    const rulesResponse = await elbClient.describeRules(params).promise();
    const rules = rulesResponse.Rules.filter(rule =>
      rule.Conditions.length > 0 &&
      rule.Conditions.some(condition => condition.Field === 'host-header') &&
      !rule.IsDefault
    );
    return rules;
  };

  const fetchInstanceIdFromTargetGroup = async (targetGroupArn) => {
    const elbClient = await getELBv2Client();
    const targetHealth = await elbClient.describeTargetHealth({ TargetGroupArn: targetGroupArn }).promise();
    const instanceId = targetHealth.TargetHealthDescriptions[0].Target.Id;
    return instanceId;
  };

  const fetchServersInfo = async () => {
    const rules = await fetchListenerRules();
    const serverInfoPromises = rules.map(async (rule) => {
      const hostHeader = rule.Conditions[0].HostHeaderConfig.Values[0];
      const targetGroupArn = rule.Actions[0].TargetGroupArn;
      const instanceId = await fetchInstanceIdFromTargetGroup(targetGroupArn);
      const instanceState = await fetchInstanceState(instanceId);
      return { hostHeader, instanceId, instanceState };
    });
    return Promise.all(serverInfoPromises);
  };


  const fetchInstanceState = async (instanceId) => {
    const ec2Client = await getEC2Client();
    const response = await ec2Client.describeInstances({ InstanceIds: [instanceId] }).promise();
    return response.Reservations[0].Instances[0].State.Name;
  };

  const refreshInstanceStates = async () => {
    const newStatePromises = servers.map(async server => {
      const currentState = await fetchInstanceState(server.instanceId);
      return { id: server.instanceId, state: currentState };
    });

    const newStates = await Promise.all(newStatePromises);
    const newStateObject = newStates.reduce((acc, { id, state }) => {
      acc[id] = state;
      return acc;
    }, {});

    setInstanceStates(newStateObject);
  };

  const restartInstance = async (instanceId) => {
    const ec2Client = await getEC2Client();
    try {
      await ec2Client.rebootInstances({ InstanceIds: [instanceId] }).promise();
      const newState = await fetchInstanceState(instanceId);
      setInstanceStates(prevStates => ({ ...prevStates, [instanceId]: newState }));
    } catch (error) {
      console.error('Error restarting instance:', error);
      alert('Failed to restart instance');
    }
  };


  const startInstance = async (instanceId) => {
    const ec2Client = await getEC2Client();
    try {
      await ec2Client.startInstances({ InstanceIds: [instanceId] }).promise();
      const newState = await fetchInstanceState(instanceId);
      setInstanceStates(prevStates => ({ ...prevStates, [instanceId]: newState }));
    } catch (error) {
      console.error('Error starting instance:', error);
      alert('Failed to start instance');
    }
    fetchInstanceState();
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      refreshInstanceStates();
    }, 5000);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [servers]);

  useEffect(() => {
    const fetchServers = async () => {
      const serversInfo = await fetchServersInfo();
      const newStates = serversInfo.reduce((acc, server) => {
        acc[server.instanceId] = server.instanceState;
        return acc;
      }, {});
      setServers(serversInfo);
      setInstanceStates(newStates);
    };
    fetchServers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchUserAttributes().then((attributes) => {
      setUserName(attributes.name);
    });
  }, []);

  return (
    <div id="app">
      <div id="app-wrapper">
        <header>
          <p className="user-name">{userName}</p>
          <Button className="sign-out" onClick={signOut} variant="primary">Sign out</Button>
        </header>
        <div className="server-items">
          {servers.map((server, index) => {
            const instanceState = instanceStates[server.instanceId];
            return (
              <div key={index} className="server-item">
                <div className="server-name-link">
                  <p>{server.hostHeader.split('.')[0]}</p>
                  <a className="external" href={`https://${server.hostHeader}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path fill="black" d="M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h82.7L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H320zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z"/>
                    </svg>
                  </a>
                </div>
                <div className={`server-status ${instanceState}`}>
                  {instanceState} {instanceState === 'running' && <div className="blinking-dot"/>}
                </div>
                <div className="action-buttons">
                  <Button
                    className={`action-button ${instanceState !== 'stopped' ? 'disabled' : ''}`}
                    onClick={() => startInstance(server.instanceId)}
                    disabled={instanceState !== 'stopped'}
                    variant="primary"
                  >
                    Start
                  </Button>
                  <Button
                    className={`action-button ${instanceState !== 'running' ? 'disabled' : ''}`}
                    onClick={() => restartInstance(server.instanceId)}
                    disabled={instanceState !== 'running'}
                    variant="primary"
                  >
                    Restart
                  </Button>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  );
}


export default withAuthenticator(App);
