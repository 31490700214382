export const config = {
    Auth: {
      Cognito: {
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        userPoolClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
        identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
        allowGuestAccess: false,
        loginWith: {
          oauth: {
            domain: process.env.REACT_APP_LOGIN_DOMAIN,
            scopes: [
              'phone',
              'email',
              'profile',
              'openid',
              'aws.cognito.signin.user.admin'
            ],
            redirectSignIn: ['https://example.com'],
            redirectSignOut: ['https://example.com'],
            responseType: 'code'
          }
        }
      }
    }
  }
